$black: #1D1D1B
$sans-font: 'Roboto', sans-serif
$serif-font: 'Noto Serif', serif

*, *::before, *::after
	box-sizing: border-box
	margin: 0
	padding: 0

body
	font-family: $serif-font
	font-size: 16px
	line-height: 1.1em
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	text-rendering: optimizeLegibility
	image-rendering: -moz-crisp-edges /* Firefox */
	image-rendering: -o-crisp-edges /* Opera */
	image-rendering: -webkit-optimize-contrast /* Webkit (non-standard naming) */
	image-rendering: crisp-edges
	-ms-interpolation-mode: nearest-neighbor /* IE (non-standard property) */

h1
	font-size: 1.25rem
	color: $black
	margin: 0
	font-family: $sans-font
	margin-bottom: 3rem

img
	width: 100%

a
	text-decoration: none
	color: $black

a.bordered
	padding-bottom: 2px
	border-bottom: 1px solid transparent
	transition: border-bottom 200ms ease-out
	display: inline-block

	&:hover
		border-color: $black

main
	padding: 2rem
	display: flex
	flex-direction: column
	min-height: 100vh
	height: 100%
	align-items: center
	justify-content: center

section
	text-align: center

section.logo
	width: 370px
	position: relative

	@media screen and (max-width: 500px)
		width: 320px

	&::after
		content: " "
		display: block
		height: 2px
		background-color: $black
		width: 140px
		margin: 2rem auto

address
	display: flex
	flex-direction: column
	font-style: normal
	align-items: center
	span
		margin-bottom: .5rem

.address-line
	margin-top: 4rem
	